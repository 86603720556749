import { post } from '@/utils/request';

// 赎回统计-获取赎回统计默认或设置项
const getStatistical = (data) => post({
    url: '/store/statistical.redemption/getStatistical',
    data: data
});

// 赎回统计-编辑赎回统计表
const editStatistical = (data) => post({
    url: '/store/statistical.redemption/editStatistical',
    data: data
})


// 赎回统计-新增赎回统计表
const addStatistical = (data) => post({
    url: '/store/statistical.redemption/addStatistical',
    data: data
})

// 赎回统计-获取赎回统计表列表
const getStatisticalList = (data) => post({
    url: '/store/statistical.redemption/getStatisticalList',
    data: data
})

// 赎回统计-获取统计维度数据
const getTotalStatisticalData = (data) => post({
    url: '/store/statistical.redemption/totalStatisticalData',
    data: data
})

// 赎回统计-获取雷达图数据
const getTotalStatisticalRadar = (data) => post({
    url: '/store/statistical.redemption/totalStatisticalRadar',
    data: data
})

// 赎回统计-时间轴数据
const getTotalStatisticalTime = (data) => post({
    url: '/store/statistical.redemption/totalStatisticalTime',
    data: data
})

// 明细
const getTotalStatisticalList = (data) => post({
    url: '/store/statistical.redemption/totalStatisticalList',
    data: data
})

const getAttrValueList = (data) => post({
    url: '/store/common.select/getAttrValueList',
    data: data
})

const getMaterialListReq = () => post({
    url: '/store/common.Select/getMaterialList',
});


// 导出
const downloadList = (data) => post({
    url: '/store/statistical.redemption/downloadList',
    responseType: 'blob',
    data: data
})

// 删除
const deleteStatistical = (data) => post({
    url: '/store/statistical.redemption/deleteStatistical',
    data: data
})

// 仓库
const getWarehouseListAll = (data) => post({
    url: '/store/goods.EntryCtl/getWarehouseListAll',
    data: data
})
// 数据分析
const totalStatisticalData2D = (data) => post({
    url: '/store/statistical.redemption/totalStatisticalData2D',
    data: data
})

// 数据分析-导出
const totalStatisticalData2DDownload = (data) => post({
    url: '/store/statistical.redemption/totalStatisticalData2DDownload',
    responseType: 'blob',
    data: data
})


export {
    getStatistical,
    editStatistical,
    addStatistical,
    getStatisticalList,
    getTotalStatisticalData,
    getTotalStatisticalRadar,
    getTotalStatisticalTime,
    getTotalStatisticalList,
    getAttrValueList,
    downloadList,
    getMaterialListReq,
    deleteStatistical,
    getWarehouseListAll,
    totalStatisticalData2D,
    totalStatisticalData2DDownload
}
